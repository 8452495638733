<template>
  <div class="FindPwd">
    <el-alert v-if="errorMsg"
      :title="errorMsg"
      type="error"
      center
      effect="dark">
    </el-alert>
    <el-alert v-if="successMsg"
      :title="successMsg"
      type="success"
      center
      effect="dark">
    </el-alert>
    <div class="box">
      <div class="title">找回密码</div>

      <div class="context">
        <el-form
          class="form"
          :rules="loginRules"
          ref="loginForm"
          label-position="right"
          label-width="80px"
          size="medium"
          :model="formLogin"
        >
          <el-form-item class="input-label" label="手机号码" prop="username">
            <el-input
              v-model="formLogin.username"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="input-label input-lable-code"
            label="图片验证码"
            prop="imgcode"
          >
            <el-input
              class="input input-code"
              v-model="formLogin.imgcode"
            ></el-input>
            <img
              class="imgcode"
              @click="onImageCodeClick"
              :src="api + '/code?t=' + random"
            />
          </el-form-item>
          <el-form-item
            class="input-label input-lable-code"
            label="短信验证码"
            prop="imgcode"
          >
            <el-input
              class="input input-code"
              v-model="formLogin.smscode"
            ></el-input>
            <el-button @click="sendSmsCode" v-ripple>{{smsCodeText}}</el-button>
          </el-form-item>
          <el-divider>输入新密码</el-divider>
          <el-form-item class="input-label" label="新密码" prop="password">
            <el-input class="input-pwd"
              v-model="formLogin.pwd"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="input-label" label="确认新密码" prop="password">
            <el-input class="input-pwd"
              v-model="formLogin.pwd2"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <div class="btn-line">
            <el-button
              class="btn"
              type="primary"
              @click="onSubmitClick('loginForm')"
              >修改密码</el-button
            >
          </div>
          <div class="links">
            <!-- <router-link to="/pub/note">业务说明</router-link> | -->
            <router-link to="/pub/apply">注册帐号</router-link> |
            <router-link to="/pub/login">登录</router-link>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindPwd',
  data() {
    return {
      errorMsg: '',
      successMsg: '',
      api: "",
      random: 0.123,
      formLogin: {
        username: "18950010066",
        pwd: '',
        pwd2: '',
        imgcode: "",
        smscode: '',
        client_id: 'web',
      },
      loginRules: {
        username: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1(3|4|5|6|7|8)\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ],
        pwd2: [
          {
            required: true,
            message: "请输入正确的密码",
            min: 6,
            max: 16,
            trigger: "blur",
          },
        ],
        imgcode: [
          {
            required: true,
            message: "请输入正确的验证码",
            min: 4,
            max: 4,
            trigger: "blur",
          },
        ],
      },
      smsCodeText: '获取验证码',
      smsSendFlag: false,
      smsCountTime: 60,
      myinterval: false,
    };
  },
  created (opt) {
    this.api = this.global.api;
    this.onImageCodeClick();
  },
  methods: {
    sendSmsCode() {
      this.smsSendFlag = false
      if (!this.util.checkPhone(this.formLogin.username)) {
        this.errorMsg = '手机号码不正确~'
        return
      }
      if (this.formLogin.imgcode.length != 4) {
        this.errorMsg = '图片验证码不正确~'
        return
      }
      var pm = {
        client_id: 'web',
        username: this.formLogin.username,
        imgcode: this.formLogin.imgcode,
        hash: this.random,
        act: '找回密码'
      }
      this.post('api/pub/sendSmsCode', pm, res=>{
        if (res.code != 0) {
          this.errorMsg = '发送短信验证码失败~，' + res.msg
          return
        }
        this.toast(res.msg)
        this.smsSendFlag = true
        this.successMsg = '短信验证码已经发送到你的手机，请注意查收~'
        this.countDown()
      })
    },
    async countDown() {
      this.myinterval = setInterval(()=>{
        this.smsCountTime --;
        this.smsCodeText = this.smsCountTime + '秒'
        if (this.smsCountTime <= 0) {
          clearInterval(this.myinterval)
          this.smsCodeText = '获取验证码'
          this.myinterval = false
          this.smsCountTime = 60
        }
      }, 1000);
    },
    onSubmitClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formLogin.pwd != this.formLogin.pwd2) {
            this.errorMsg = '两次输入的密码不同~'
            return
          }
          this.formLogin.hash = this.random
          this.post('api/pub/findPwdChange', this.formLogin, res=>{
            if (res.code != 0) {
              this.errorMsg = '修改密码失败：' + res.msg
              return;
            }
            this.errorMsg = ''
            this.successMsg = '修改密码成功~'
            this.toast(this.successMsg)
          })
        } else {
          this.alertMsg('提交失败，请检查数据是否正确~')
        }
      });
    },
    onImageCodeClick() {
      this.random = this.util.uuid();
    },
  } // methods
}
</script>

<style scoped lang="scss">
.FindPwd {
  margin-top: 0px;
}
.box {
  min-height: 10vh;
  width: 35em;
  margin: 10vh auto;
  border: 1px solid #1f5081;
  border-radius: 15px;
}
.title {
  color: #fff;
  font-size: 1.5em;
  line-height: 3em;
  letter-spacing: 0.4em;
  height: 3em;
  background-color: #1f5081;
  border-radius: 15px 15px 0 0;
}
.context {
  text-align: left;
  padding: 2em;
}

.form {
  display: flex;
  flex-direction: column;
}
.input-label {
  margin-top: 0.3em;
}

.input {
  width: 18em;
}

.input-pwd{
  width: 12em;
}

.input-code {
  width: 9em;
  float: left;
}

.imgcode {
  float: left;
  height: 2.5em;
  margin-left: 1em;
}
.btn-line {
  display: flex;
}
.btn {
  width: 10em;
  margin-top: 2em;
  margin: 2em auto 0;
}

.links {
  margin-top: 20px;
  text-align: center;
}
</style>
